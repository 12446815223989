import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "De Jekyll à Hugo",
  "description": "Tutoriel sur la manière dont j’ai remplacé Jekyll, devenu trop lent, par Hugo.",
  "date": "2017-09-02T00:00:00.000Z",
  "path": "/articles/de-jekyll-a-hugo/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mon site est sous Jekyll depuis `}<a parentName="p" {...{
        "href": "/articles/goodbye-wordpress-hello-jekyll"
      }}>{`un bon moment`}</a>{`, mais j’ai remarqué qu’il devenait de plus en plus lent pour compiler tout le contenu que j’ai créé au fil du temps. J’ai donc décidé de le migrer sous `}<a parentName="p" {...{
        "href": "https://gohugo.io/"
      }}>{`Hugo`}</a>{`, dont j’ai eu de bons échos en termes de performances. J’en profite également pour faire un tuto de migration pour les personnes intéressées.`}</p>
    <p className="info">
  Cet article part du principe que vous êtes à l’aise avec certains mécanismes
  également utilisés par Jekyll (Markdown, YAML, <em>partials</em>, etc.).
    </p>
    <h2>{`Pourquoi Hugo ?`}</h2>
    <p>{`Il existe `}<a parentName="p" {...{
        "href": "https://www.staticgen.com/"
      }}>{`une énorme quantité`}</a>{` de générateurs sites statiques, et parmi ceux-ci le choix peut s’avérer difficile. J’étais à la recherche du générateur qui m’apporterait `}<strong parentName="p">{`d’excellentes performances`}</strong>{` en termes de compilation tout en évitant d’avoir à changer trop de choses dans le code de mon site.`}</p>
    <p>{`Hugo revient régulièrement dans les discussions en ligne, surtout quand il s’agit de faire un comparatif avec `}<a parentName="p" {...{
        "href": "https://novelist.xyz/tech/hugo-vs-jekyll-static-site-generator/"
      }}>{`Jekyll`}</a>{`. Ecrit en Go, Hugo offre des temps de compilation réduits drastiquement par rapport à ce dernier.`}</p>
    <p>{`La transition de l’un à l’autre semble également se faire relativement en douceur, comme vous le verrez en détails ci-dessous.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`On commence par télécharger et installer Hugo :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` hugo`}</code></pre></div>
    <p>{`Ou pour ceux qui sont sous Windows,`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`choco `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` hugo `}<span parentName="code" {...{
            "className": "token parameter variable"
          }}>{`-confirm`}</span></code></pre></div>
    <p>{`Ensuite, pour importer le contenu d’un site Jekyll vers un site Hugo, il existe une autre commande :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`hugo `}<span parentName="code" {...{
            "className": "token function"
          }}>{`import`}</span>{` jekyll `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`chemin-jekyll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`chemin-cible`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Qui va créer l’arborescence suivante :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`content
data
layouts
static
themes
config.toml`}</code></pre></div>
    <p>{`Dans mon cas, les articles ont été placés dans `}<code parentName="p" {...{
        "className": "text"
      }}>{`content/post`}</code>{`, et les fichiers et dossiers situés à la racine du site Jekyll ont été placés dans `}<code parentName="p" {...{
        "className": "text"
      }}>{`static`}</code>{`… J’ai dû faire un peu de nettoyage pour redéplacer ce qu’il fallait au bon endroit (je reviendrai sur l’arborescence ci-dessus un peu plus tard).`}</p>
    <p>{`Notez qu’il est aussi possible de créer un nouveau site directement si il vous prend l’envie saugrenue de migrer tous vos articles à la main :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`hugo new site mon-site`}</code></pre></div>
    <p>{`Pour finir on lance un serveur pour surveiller tout nouveau changement (accessible sur `}<code parentName="p" {...{
        "className": "text"
      }}>{`localhost:1313`}</code>{`) :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`hugo server`}</code></pre></div>
    <h2>{`Création de contenu`}</h2>
    <p>{`C’est dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`content`}</code>{` que vont se placer tous les types de contenu : articles de blog, pages statiques, 404, page d’accueil etc., le tout au format `}<code parentName="p" {...{
        "className": "text"
      }}>{`.md`}</code>{`.`}</p>
    <p>{`Pour que ces pages ressemblent à quelque chose, il faudra leur créer des `}<strong parentName="p">{`templates`}</strong>{`. Ceux-ci sont placés dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`layouts`}</code>{`. C’est également là-dedans que sont placés les `}<em parentName="p">{`partials`}</em>{` réutilisables (header, footer, etc.).`}</p>
    <p>{`Le template utilisé par chaque page de contenu est défini dans son `}<em parentName="p">{`front matter`}</em>{` par la propriété `}<strong parentName="p">{`type`}</strong>{`, qui peut être écrit au format `}<code parentName="p" {...{
        "className": "text"
      }}>{`yaml`}</code>{` ou `}<code parentName="p" {...{
        "className": "text"
      }}>{`toml`}</code>{`.`}</p>
    <p>{`Par exemple, voici ma page `}<code parentName="p" {...{
        "className": "text"
      }}>{`projets.md`}</code>{` :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "markdown"
      }}><code parentName="pre" {...{
          "className": "markdown"
        }}>{`type: "static"
title: "Projets récents"
description : "Découvrez mes derniers projets et réalisations web."

`}<span parentName="code" {...{
            "className": "token hr punctuation"
          }}>{`---`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Bla bla bla écrit en Markdown`}</span></code></pre></div>
    <p>{`Le template en question est un fichier `}<code parentName="p" {...{
        "className": "text"
      }}>{`single.html`}</code>{` situé dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`layouts/static`}</code>{`. Voilà à quoi il pourrait ressembler :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "go"
      }}><code parentName="pre" {...{
          "className": "go"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"header.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Content `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"footer.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "text"
      }}>{`.Content`}</code>{` insère le contenu de `}<code parentName="p" {...{
        "className": "text"
      }}>{`projets.md`}</code>{`, et les `}<em parentName="p">{`partials`}</em>{`, comme leur nom l’indique… importent les `}<em parentName="p">{`partials`}</em>{` en question.`}</p>
    <p className="info">
  Pour plus de détails sur les différents templates utilisés par Hugo je vous
  invite à consulter la doc officielle.
    </p>
    <p>{`Pour ce qui est des pages du blog, le principe est à peu près similaire. J’ai créé dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`layouts`}</code>{` un sous-dossier intitulé `}<code parentName="p" {...{
        "className": "text"
      }}>{`articles`}</code>{`. Celui-ci contient deux templates : `}<code parentName="p" {...{
        "className": "text"
      }}>{`list.html`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`single.html`}</code>{`.`}</p>
    <h3>{`Liste d’articles`}</h3>
    <p>{`J’ai sur mon site une page qui contient une liste de tous les articles publiés, triés par année. Voilà le code (simplifié) :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "go"
      }}><code parentName="pre" {...{
          "className": "go"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"header.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`h1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Title `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`h1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`range`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Pages`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GroupByDate `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2006"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`h2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Key `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`h2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`ul`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`range`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Pages `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`li`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`a href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ .RelPermalink }}"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`h3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Title `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`h3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Format `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"02.01"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`li`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` end `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`ul`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` end `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"footer.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Les deux `}<code parentName="p" {...{
        "className": "text"
      }}>{`range`}</code>{` permettent d’itérer, pour le premier sur les dates par années, et pour le deuxième sur la liste des articles.`}</p>
    <p>{`Et le résultat :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`2015

NOMMAGE ET ARCHITECTURE CSS  19.08
UNE GESTION RESPONSABLE DES WEBFONTS  25.06
LES PRINCIPALES NOUVEAUTÉS DE ES6  27.04
DROPBOX ET NODE_MODULES  02.04

2014

METTRE SES ASSETS EN CACHE AVEC JEKYLL  17.12
ANIMER SES SVG AVEC SNAP.SVG  21.07

etc.`}</code></pre></div>
    <h3>{`Page d’article`}</h3>
    <p>{`Voilà le template, là aussi simplifié :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "go"
      }}><code parentName="pre" {...{
          "className": "go"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"header.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Day `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` index `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Site`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mois `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`printf `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"%d"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Month`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Year `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`h1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Title `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`h1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Content `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` partial `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"footer.html"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Celui-ci m’a posé un peu plus de soucis car je souhaitais afficher la date de publication de l’article en français, au format `}<em parentName="p">{`01 janvier 2017`}</em>{`. J’ai trouvé un moyen de contourner le problème en utilisant la variable `}<code parentName="p" {...{
        "className": "text"
      }}>{`.Site.Data`}</code>{`. Il m’a suffit de créer un fichier `}<code parentName="p" {...{
        "className": "text"
      }}>{`mois.yaml`}</code>{` dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`data`}</code>{`, avec le contenu suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "yaml"
      }}><code parentName="pre" {...{
          "className": "yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"janvier"`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"février"`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mars"`}</span>{`
etc.`}</code></pre></div>
    <p>{`La fonction `}<code parentName="p" {...{
        "className": "text"
      }}>{`printf`}</code>{` m’a ensuite permis de récupérer les bonnes traductions.`}</p>
    <h2>{`Gestion du cache`}</h2>
    <p className="info">
  J’utilise Gulp pour compiler et optimiser les <em>assets</em> de mon site. Si
  jamais votre workflow est différent (webpack ou autre), il vous sera à priori
  facile d’adapter l’exemple ci-dessous à votre cas précis.
    </p>
    <p>{`Pour rappel, le but ici est d’ajouter un `}<em parentName="p">{`fingerprint`}</em>{` au nom des fichiers voulus. Par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`rel`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/assets/css/styles.css`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`Doit être transformé en :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`rel`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/assets/css/styles-b9bafce7.css`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`Les `}<em parentName="p">{`assets`}</em>{` de mon site Jekyll (CSS, JS, etc.) étaient `}<em parentName="p">{`fingerprintés`}</em>{` grâce à `}<a parentName="p" {...{
        "href": "https://github.com/tkareine/jekyll-minibundle"
      }}>{`jekyll-minibundle`}</a>{`. Avec Hugo j’ai dû chercher une nouvelle solution pour arriver à un résultat équivalent. Je me suis servi pour ça de `}<code parentName="p" {...{
        "className": "text"
      }}>{`gulp-hash`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`del`}</code>{`.`}</p>
    <p>{`Pour arriver à la solution voulue, il faut pouvoir stocker dans le dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`data`}</code>{` un fichier qui mappera les noms d’`}<em parentName="p">{`assets`}</em>{` avec le dernier `}<em parentName="p">{`hash`}</em>{` créé. Ces informations seront ensuite accessibles à Hugo grâce à la variable globale `}<code parentName="p" {...{
        "className": "text"
      }}>{`.Site.Data`}</code>{`.`}</p>
    <p>{`Voici par exemple ma tâche Gulp pour la gestion des styles :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}>{`gulp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`task`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'styles'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// On supprime l’ancien fichier CSS`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`del`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`distFolder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`css `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'**/*'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    gulp
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`src`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`devFolder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`scss `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'styles.scss'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$$`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$$`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`autoprefixer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$$`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`cssnano`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// On crée un hash`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$$`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`hash`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`gulp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`dest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`distFolder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`css`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// On mappe le fichier`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$$`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`manifest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'hashcss.json'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// On le place dans data`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`gulp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`dest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Ensuite, il suffit d’aller modifier le template pour y intégrer cette donnée :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "go"
      }}><code parentName="pre" {...{
          "className": "go"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`link rel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"stylesheet"`}</span>{` href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/assets/css/{{ index .Site.Data.hashcss "`}</span>{`styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`css`}<span parentName="code" {...{
            "className": "token string"
          }}>{`" }}"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Le principe est le même pour vos autres fichiers statiques à cacher.`}</p>
    <h2>{`Mise en production`}</h2>
    <p>{`Hugo crée un dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`public`}</code>{` qui contiendra l’ensemble du site. Il suffit de lancer la commande `}<code parentName="p" {...{
        "className": "text"
      }}>{`hugo`}</code>{` pour cela.`}</p>
    <h2>{`Autres infos utiles`}</h2>
    <p>{`Je ne me suis pas encore penché sur trois dossiers présents à la racine d’un site lors de sa création: `}<code parentName="p" {...{
        "className": "text"
      }}>{`archetypes`}</code>{` , `}<code parentName="p" {...{
        "className": "text"
      }}>{`static`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`themes`}</code>{`.`}</p>
    <h3>{`Archétypes`}</h3>
    <p>{`Cette fonctionnalité permet de créer du nouveau contenu en utilisant un `}<em parentName="p">{`front matter`}</em>{` prédéfini. Par exemple, j’ai créé un archétype spécifique pour mes articles comme ceci :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` archetypes/articles.md`}</code></pre></div>
    <p>{`Celui-ci contient le `}<em parentName="p">{`front matter`}</em>{` que je souhaite utiliser pour toute nouvelle création.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "yaml"
      }}><code parentName="pre" {...{
          "className": "yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`date`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` .Date `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tags`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`
`}</code></pre></div>
    <p>{`Puis :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "bash"
      }}><code parentName="pre" {...{
          "className": "bash"
        }}>{`hugo new articles/mon-nouvel-article`}</code></pre></div>
    <h3>{`Static`}</h3>
    <p>{`Ce dossier contient par exemple dans mon cas les fichier CSS, JS, JSON, images ainsi que les pages que je veux garder indépendantes de Hugo. Ils seront placés à la racine du dossier `}<code parentName="p" {...{
        "className": "text"
      }}>{`public`}</code>{` lors de la mise en production.`}</p>
    <h3>{`Thèmes`}</h3>
    <p>{`C’est une fonctionnalité de Hugo que je n’ai pas du tout utilisée car j’ai mon propre thème maison, mais si vous voulez en savoir plus je vous invite à aller visiter la `}<a parentName="p" {...{
        "href": "https://gohugo.io/themes/"
      }}>{`documentation`}</a>{` écrite là-dessus.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Voilà, je pense avoir fait le tour des points principaux pour créer un site avec Hugo sans trop de prise de tête. Si jamais vous voyez des `}<strong parentName="p">{`détails que j’aurais oublié`}</strong>{`, n’hésitez pas à venir m’en parler sur `}<a parentName="p" {...{
        "href": "https://twitter.com/ronanlevesque"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      